import { constSystemFeature } from '../constant';
export const systemFeatureAction = {
    loadSystemFeature,
    fetchCloudFlareTurnstileSetting,
    clearSiteKey,
    resetSiteKey,
};

function loadSystemFeature() {
    return async (dispatch, getState) => {
        const { language } = getState();

        const response = await window.SPL_Content.getSystemFeature(language?.currencyLang);
        dispatch({ type: constSystemFeature.LOAD_SYSTEM_FEATURE, payload: response });
    };
}

function fetchCloudFlareTurnstileSetting() {
    return async (dispatch, getState) => {
        try {
            const response = await window.SPL_Member.getCloudflareTurnstileSetting();
            const data = response?.site_key || '';
            dispatch({ type: constSystemFeature.FETCH_TURNSTILE_SETTINGS, payload: data });
        } catch (error) {
            console.error('Error fetching cloudflare turnstile setting:', error);
            dispatch({ type: constSystemFeature.FETCH_TURNSTILE_SETTINGS, errors: error });
        }
    };
}

function clearSiteKey() {
    return (dispatch) => {
        dispatch({ type: constSystemFeature.CLEAR_SITE_KEY });
    };
}

function resetSiteKey() {
    return (dispatch) => {
        dispatch({ type: constSystemFeature.RESET_SITE_KEY });
    };
}
