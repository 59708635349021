import core from 'root/utils/core';
import notification from 'root/utils/notification';
import moment from 'moment';
import { trans_handlePGDepositCountDown } from 'root/utils/transaction-util';
import { scrollToDepositStep2 } from './deposit-util';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;

        let defaultDob = moment(Date.now()).subtract(18, 'years').toDate();
        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;

        controller.vm.state = {
            bankNameErrMsg: [],
            isLoading: true,
            hoveredIndex: null,
            depositAllSettings: { uploadReceipt: false },
            depositMinMaxLimit: { min: 0, max: 0 },
            selectedMethodIndex: 0,
            selectedBankIndex: disablePreselectBank ? null : 0,
            selectedChannelIndex: 0,
            selectedBankDetailIndex: 0,
            depositDetails: null,

            depositAmountOptions: [],
            depositOptions: [],
            methodList: [],
            bankList: [],
            receiptImg: [],
            qrPayList: [],
            reloadDetails: [],

            minMaxLimit: null,
            isFormValid: false,
            isOnlineTransfer: false,
            receiptRequired: false,
            isReceiptValid: false,
            disableDepositButton: disablePreselectBank ? true : false,
            displayDefault: undefined,

            fields: {
                amount: '',
                reference: '',
            },
            errors: {},
            amountErrorValue: 0,
            methodListCache: {},

            isCrypto: false,
            cryptoOptions: [],
            cryptoChain: '',
            chainKey: '',
            selectedCryptoIndex: 0,
            selectedChainIndex: disablePreselectBank ? null : 0,
            memberVerifyPopup: false,
            showEmail: false,
            showName: false,
            showDob: false,
            showPhone: false,
            cryptoFields: {
                fullname: '',
                email: '',
                telCode: '',
                phone: '',
                dob: defaultDob,
            },
            optionsList: {
                telCode: [],
            },
            cryptoQrCode: '',
            selectedChainMin: '',
            exchangeRate: '',
            chainBranch: '',
            chainName: '',
            chainRemark: [],
            maxDob: defaultDob,
            memberVerifyDisable: true,
            getShareInfo: false,

            isQRPay: false,
            showQrBankList: false,
            qrpaySupportedBankList: [],
            qrpaySupportedBankCache: {},
            qrPayDepositChannelObjs: [],
            qrHoveredIndex: null,

            isReload: false,
            reload_date: new Date(),
            addBankDialogIsShow: false,
            unOwnedBankList: [],

            isExpressDeposit: false,

            accountBankfields: {
                selectedBankIndex: disablePreselectBank ? null : 0,
                bankName: '',
                bankBranch: '',
                accountName: controller.vm.props.user.account.name,
                accountNumber: '',
            },
            memberBankList: [],
            bankAppsEnabled: false,
            isBankApp: false,

            verifyBank: [],
            verifyAccountList: '',
            expressDepositMemberBankList: null,
            expressDepositMerchantBankList: null,
            showNotBankSupportMsg: false,
            showAllBankMaintenaceMsg: false,
            depositSubmitCountdown: null,
        };
    },

    addButtonOnClicked() {
        controller.vm.props.history.push('/myaccount/bankingdetails');
    },

    initDepositPage() {
        core.portalIsLoading(controller.vm.props, true);
        const currency = controller.vm.props.user.account.currency;
        const account = controller.vm.props.user.account;
        let depositAllSettings = null;
        let methodList = [];
        let bankList = [];
        let qrPayList = [];
        let _selectedMethodIndex = controller.vm.state.selectedMethodIndex;

        const { filteredDepositApiJson } = this.state;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

        trans_handlePGDepositCountDown(this, false, 60, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });

        // load deposit settings json
        window.SPL_Transaction.loadDepositRelatedSettings(currency).then((depositSettings) => {
            depositAllSettings = depositSettings;

            // load 3rd party banks
            window.SPL_Transaction.loadDepositDetails(currency, null, false).then((depositDetails) => {
                methodList = depositDetails.methodList;
                bankList = depositDetails.banks || []; // if null then empty array
                qrPayList = depositDetails.qrPayList;
                let defaultMethod = depositDetails.defaultMethod;

                for (let i = 0; i < methodList.length; i++) {
                    methodList[i].isThirdParty = true;
                    methodList[i].key = methodList[i].code.toLowerCase();
                    if (methodList[i].key === 'h2p') {
                        methodList[i].key = 'help2pay';
                    }

                    if (defaultMethod && methodList[i].code === defaultMethod.code) {
                        _selectedMethodIndex = i;
                    }
                }
                // add constant online transfer method at end of the list
                let onlineTransfer = {
                    code: 'transaction:transaction.deposit.cashdeposit.banktransfer',
                    shortName: 'transaction:transaction.deposit.cashdeposit.banktransfer',
                    key: 'bank',
                    isOnlineTransfer: true,
                };
                methodList.push(onlineTransfer);

                const { currency } = controller.vm.props.user.account;
                window.SPL_Transaction.loadBankAppsEnabled(currency).then((bankAppsRes) => {
                    // add bank apps option
                    if (bankAppsRes.bankAppsEnabled) {
                        const bankAppsData = {
                            code: 'transaction:transaction.deposit.cashdeposit.bankApp',
                            shortName: 'transaction:transaction.deposit.cashdeposit.bankApp',
                            key: 'bankapp',
                            isBankApp: true,
                        };

                        methodList.push(bankAppsData);
                    }
                });

                // add crypto option
                if (depositAllSettings.enableCryptoTab) {
                    let crypto = {
                        code: 'transaction:transaction.deposit.cashdeposit.crypto',
                        shortName: 'transaction:transaction.deposit.cashdeposit.crypto',
                        key: 'crypto',
                        isCrypto: true,
                    };

                    methodList.push(crypto);
                }

                // add qr deposit option
                if (qrPayList && qrPayList.length > 0) {
                    let qrpay = {
                        code: 'transaction:transaction.deposit.cashdeposit.qrpay',
                        shortName: 'transaction:transaction.deposit.cashdeposit.qrpay',
                        key: 'qrpay',
                        isQRPay: true,
                    };
                    methodList.push(qrpay);
                }

                //add express deposit
                if (depositAllSettings.expressDepositEnabled) {
                    let expressDeposit = {
                        code: 'transaction:transaction.deposit.expressDeposit.expressDeposit',
                        shortName: 'transaction:transaction.deposit.expressDeposit.expressDeposit',
                        key: 'expressDeposit',
                        isExpressDeposit: true,
                    };
                    methodList.push(expressDeposit);
                    controller.loadExpressDepositFilteredBank();
                }

                // default cache the 1st selected
                let methodListCache = {};
                // if methodlist only length 1 , then mean only have bank transfer methods
                // then dont cache this bank list as this bank list is cache third party
                if (!methodList[_selectedMethodIndex].isOnlineTransfer) {
                    methodListCache = {
                        _selectedMethodIndex: {
                            bankList: bankList,
                        },
                    };
                }

                window.SPL_Transaction.getReloadDetails(account, currency).then((reloadDetails) => {
                    if (controller.vm.props.portal.settings.enableReloadDepositMethod && reloadDetails.bank) {
                        let reload = {
                            code: 'transaction:transaction.deposit.reload.title',
                            shortName: 'transaction:transaction.deposit.reload.title',
                            key: 'reload',
                            isReload: true,
                        };
                        methodList.push(reload);
                    }

                    controller.getCorrectDepositMinMaxLimit(depositAllSettings, methodList[0]);

                    const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
                    controller.vm.setState(
                        {
                            depositAllSettings: depositAllSettings,
                            methodList: methodList,
                            selectedMethodIndex: _selectedMethodIndex,
                            bankList: bankList,
                            qrPayList: qrPayList,
                            reloadDetails: reloadDetails,
                            methodListCache: methodListCache,
                            depositAmountOptions: depositDetails.depositOptions,
                            disableDepositButton: disablePreselectBank && !bankList?.length ? false : controller.vm.state.disableDepositButton,
                        },
                        () => {
                            if (methodList[0].isOnlineTransfer) {
                                // if methodlist only length 1 , then mean only have bank transfer methods
                                // force method change to get bank transfer
                                controller.onMethodListChanged(methodList[0], true);
                            }
                        }
                    );

                    core.portalIsLoading(controller.vm.props, false);

                    // continue load deposit option at behind
                    controller.loadBankTransferDropdownOption();
                });
            });
        });
    },

    loadDepositOptions() {
        window.SPL_Transaction.loadDepositOptions().then((data) => {
            controller.vm.setState({ depositAmountOptions: data });
        });
    },

    loadBankTransferDropdownOption() {
        const { t } = controller.vm.props;
        let depositOptions = [
            { value: 'O', label: t('settings:settings.dropdown.onlineBanking') },
            { value: 'D', label: t('settings:settings.dropdown.cashDepositMachine') },
            { value: 'A', label: t('settings:settings.dropdown.atmTransfer') },
        ];

        if (controller.vm.state.depositAllSettings.showOnlineBankingOnly) {
            depositOptions = depositOptions.filter((a) => a.value === 'O');
        } else {
            if (controller.vm.state.depositAllSettings.hideATM) {
                depositOptions = depositOptions.filter((a) => a.value !== 'A');
            }
            if (controller.vm.state.depositAllSettings.hideCashDeposit) {
                depositOptions = depositOptions.filter((a) => a.value !== 'D');
            }
        }

        controller.vm.setState({ depositOptions: depositOptions }, () => {
            controller.hideshowOnlineBank();
        });
    },

    changeChannel(e) {
        controller.vm.setState({ selectedChannelIndex: e.target.value }, () => {
            controller.hideshowOnlineBank();
            controller.checkCertainBankRequiredReceipt();
        });
    },

    changeQRChannel(index) {
        const { qrPayList, bankList, qrPayDepositChannelObjs } = controller.vm.state;
        let currQRPayList = qrPayList;
        currQRPayList = bankList.filter((e) => e.bank && e.bank.channel === qrPayDepositChannelObjs[index].channel);

        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
        controller.vm.setState({ selectedChannelIndex: index, qrPayList: currQRPayList, selectedBankIndex: disablePreselectBank ? null : 0 }, () => {
            controller.onBankChanged(currQRPayList[0]);
        });
    },

    handleBankDetailChange(e) {
        const { verifyBank } = controller.vm.state;

        let verifyAccountList = '';
        let isFormValid = false;

        if (verifyBank && verifyBank[e.target.value]) {
            verifyAccountList = verifyBank[e.target.value];
            isFormValid = true;
        }

        controller.vm.setState(
            { selectedBankDetailIndex: e.target.value, verifyAccountList: verifyAccountList, isFormValid: isFormValid, selectedChannel: e.target.value },
            () => {
                controller.isValidForm();
                controller.checkCertainBankRequiredReceipt();
            }
        );
    },

    openAddBankDialog() {
        controller.vm.setState({ addBankDialogIsShow: true });
    },

    hideshowOnlineBank() {
        if (controller.vm.state.depositAllSettings.allowOnlineBankDetails) {
            let channel = controller.vm.state.depositOptions[controller.vm.state.selectedChannelIndex];
            if (channel.value === 'O' || channel.value === 'A') {
                controller.vm.setState({ showOnlineBankDetails: true });
            } else {
                controller.vm.setState({ showOnlineBankDetails: false, receiptRequired: false });
            }
        }
    },

    getCorrectDepositMinMaxLimit(depositAllSettings, method, qrSelectedIndex = null) {
        const currency = controller.vm.props.user.account.currency;
        const { qrPayList } = controller.vm.state;
        let depositMinMaxLimit = { min: 0, max: 0 };
        if (qrSelectedIndex === null && method.isQRPay) {
            qrSelectedIndex = 0;
        }

        if (method.isThirdParty && depositAllSettings.depositJson.quickPay[method.code]) {
            depositMinMaxLimit = depositAllSettings.depositJson.quickPay[method.code][currency];
        } else if (method.isQRPay && qrPayList.length > 0 && depositAllSettings.depositJson.quickPay[qrPayList[qrSelectedIndex].code]) {
            depositMinMaxLimit = depositAllSettings.depositJson.quickPay[qrPayList[qrSelectedIndex].code][currency];
        } else if (method.isReload) {
            depositMinMaxLimit = depositAllSettings;
        } else {
            depositMinMaxLimit = depositAllSettings.depositJson.onlinetransfer[currency];
        }
        controller.vm.setState({ depositMinMaxLimit: depositMinMaxLimit });
    },

    resetForm() {
        controller.vm.setState(
            {
                fields: {
                    amount: '',
                    reference: '',
                },
                errors: {},
                receiptRequired: false,
                receiptImg: [],
                receiptImgFile: null,
                isReceiptValid: false,
                verifyAccountList: {
                    accountNumber: '',
                    accountName: '',
                },
            },
            () => {
                controller.handleValidation();
            }
        );
    },

    // force to force load method
    onMethodListChanged(methodObj, force) {
        const { selectedMethodIndex, methodList, methodListCache, depositAllSettings, qrPayList, reloadDetails, selectedChannelIndex } = controller.vm.state;
        const { user } = controller.vm.props;
        let foundIndex = methodList.indexOf(methodObj);
        let isOnlineTransfer = methodObj.isOnlineTransfer ? methodObj.isOnlineTransfer : false;
        let isCrypto = methodObj.isCrypto ? methodObj.isCrypto : false;
        let isQRPay = methodObj.isQRPay ? methodObj.isQRPay : false;
        let isReload = methodObj.isReload ? methodObj.isReload : false;
        let isBankApp = methodObj.isBankApp ? methodObj.isBankApp : false;
        let isExpressDeposit = methodObj.isExpressDeposit ? methodObj.isExpressDeposit : false;

        for (let i = 0; i < [reloadDetails].length; i++) {
            if ([reloadDetails][i].bank) {
                [reloadDetails][i].shortBankName = [reloadDetails][i].bank.name;
                [reloadDetails][i].name = [reloadDetails][i].bank.name;
                [reloadDetails][i].code = [reloadDetails][i].bank.bankCode ? [reloadDetails][i].bank.bankCode : 'true_money';
            }
            [reloadDetails][i].isReload = true;
            [reloadDetails][i].min = [reloadDetails][i].minLimit;
            [reloadDetails][i].max = [reloadDetails][i].maxLimit;
        }

        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
        controller.vm.setState(
            {
                isOnlineTransfer: isOnlineTransfer,
                isCrypto: isCrypto,
                isQRPay: isQRPay,
                isReload: isReload,
                isBankApp,
                isExpressDeposit,
                bankList: [],
                disableDepositButton: disablePreselectBank ? false : controller.vm.state.disableDepositButton,
            },
            () => {
                controller.resetForm();

                if (foundIndex !== selectedMethodIndex || force) {
                    if (!methodListCache[foundIndex]) {
                        core.portalIsLoading(controller.vm.props, true);
                        // if cache does not contain the method bank list, trigger api to get and cache

                        // NOTE: need to refactor entire deposit related code, else next time more suffering on maintenance.
                        if (methodObj.isThirdParty && !methodObj.isQRPay) {
                            window.SPL_Transaction.getDepositDetailsOnMethodChange(methodList, methodObj, user.account.currency).then((data) => {
                                storeAndUpdateList(depositAllSettings, methodObj, foundIndex, data.banks);
                            });
                        } else if (methodObj.isOnlineTransfer) {
                            window.SPL_Transaction.loadMerchantBank().then((merchantBankList) => {
                                for (let i = 0; i < merchantBankList.length; i++) {
                                    merchantBankList[i].code = merchantBankList[i].bankCode;
                                }
                                storeAndUpdateList(depositAllSettings, methodObj, foundIndex, merchantBankList);
                            });
                        } else if (methodObj.isExpressDeposit) {
                            window.SPL_Transaction.loadMerchantBank().then((merchantBankList) => {
                                for (let i = 0; i < merchantBankList.length; i++) {
                                    merchantBankList[i].code = merchantBankList[i].bankCode;
                                }
                                storeAndUpdateList(depositAllSettings, methodObj, foundIndex, merchantBankList);
                            });
                        } else if (methodObj.isBankApp) {
                            const { account } = user;
                            if (account && account.currency) {
                                window.SPL_Transaction.loadBankAppsSettingsAndData(account.currency).then((bankListRes) => {
                                    storeAndUpdateList(depositAllSettings, methodObj, foundIndex, bankListRes.bankAppBankList);
                                });
                            }
                        } else if (methodObj.isCrypto) {
                            let params = {
                                purpose: 'D',
                                option: 'CT',
                            };

                            window.SPL_Transaction.getMerchantBankGroupWithOption(params).then((data) => {
                                if (data && data.channelOptions && data.options) {
                                    for (let i = 0; i < data.channelOptions.length; i++) {
                                        data.channelOptions[i].displayName = controller.vm.props.t(data.channelOptions[i].displayName);
                                    }

                                    controller.vm.setState(
                                        {
                                            cryptoOptions: data.channelOptions,
                                            chainKey: data.channelOptions[controller.vm.state.selectedCryptoIndex].key,
                                            selectedMethodIndex: foundIndex,
                                        },
                                        () => {
                                            controller.vm.setState({ cryptoChain: data.options[controller.vm.state.chainKey] }, () => {
                                                controller.checkChainOptions();
                                            });
                                        }
                                    );
                                }
                            });
                        } else if (methodObj.isQRPay) {
                            let qrPayDepositChannelObjs = [
                                /*{ channel: 'test', channelName: 'dummy' }*/
                            ];
                            let qrPayDepositChannels = [];

                            for (let i = 0; i < qrPayList.length; i++) {
                                const currentQRPay = qrPayList[i];
                                currentQRPay.depositChannelName = window.SPL_Transaction.getQRDepositChannelName(currentQRPay.bank);
                                currentQRPay.isQRPay = true;

                                if (currentQRPay.bank.channel) {
                                    let qrPayDepositChannelsObj = { channel: currentQRPay.bank.channel, channelName: currentQRPay.depositChannelName };
                                    qrPayDepositChannelObjs.push(qrPayDepositChannelsObj);
                                    qrPayDepositChannels.push(currentQRPay.bank.channel);
                                }
                            }

                            for (let j = qrPayDepositChannels.length - 1, l = 0; j > l; j--) {
                                if (qrPayDepositChannels.indexOf(qrPayDepositChannels[j]) !== j) {
                                    qrPayDepositChannelObjs.splice(j, 1);
                                }
                            }

                            let currQRPayList = qrPayList;
                            currQRPayList = qrPayList.filter((e) => e.bank && e.bank.channel === qrPayDepositChannelObjs[0].channel);
                            controller.vm.setState({ qrPayDepositChannelObjs, qrPayList: currQRPayList });

                            storeAndUpdateList(depositAllSettings, methodObj, foundIndex, qrPayList);
                        } else if (methodObj.isReload) {
                            storeAndUpdateList(reloadDetails, methodObj, foundIndex, [reloadDetails]);
                            controller.handleReceiptValidation();
                        }
                    } else {
                        // quickly get from cache the bank list
                        if (methodObj.isReload) {
                            controller.getCorrectDepositMinMaxLimit(reloadDetails, methodObj);

                            setTimeout(() => {
                                controller.handleReceiptValidation();
                            }, 100);
                        } else {
                            controller.getCorrectDepositMinMaxLimit(depositAllSettings, methodObj);
                        }

                        updateBankList(foundIndex, methodListCache[foundIndex].bankList);
                    }
                }
            }
        );

        function storeAndUpdateList(depositAllSettings, methodObj, foundIndex, banks) {
            controller.getCorrectDepositMinMaxLimit(depositAllSettings, methodObj);
            storeMethodCache(foundIndex, banks);
            updateBankList(foundIndex, banks);
        }

        function updateBankList(index, bankList) {
            for (let i = 0; i < bankList.length; i++) {
                if (bankList[i].code === 'H2PP') {
                    bankList[i].shortBankName = 'Help2Pay';
                    bankList[i].name = 'Help2Pay';
                }
            }

            controller.resetForm();
            const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
            controller.vm.setState(
                {
                    selectedMethodIndex: index,
                    bankList: bankList,
                    selectedBankIndex: disablePreselectBank ? null : 0,
                    disableDepositButton: disablePreselectBank && !bankList?.length ? false : controller.vm.state.disableDepositButton,
                },
                () => {
                    controller.checkCertainBankRequiredReceipt();
                }
            );
            if (controller.vm.state.isQRPay) {
                controller.getQrPaySupportedBankList(bankList[0]);
            }
            core.portalIsLoading(controller.vm.props, false);
        }

        function storeMethodCache(index, bankList) {
            let methodListCache = controller.vm.state.methodListCache;
            methodListCache[index] = {
                bankList: bankList,
            };
            controller.vm.setState({ methodListCache });
        }
    },

    onBankChanged(bankObj, onChangeValue) {
        const { selectedBankIndex, bankList, isQRPay, depositAllSettings, selectedMethodIndex, methodList, qrPayList } = controller.vm.state;
        const {
            enableScrollToDepositStep2 = false,
            scrollToDepositStep2Attempts = 15,
            scrollToDepositStep2Delay = 200,
        } = controller.vm.props.portal?.settings?.features?.depositSettings || {};

        if (onChangeValue !== undefined) {
            if (!bankObj) {
                bankObj = bankList[onChangeValue];
            }

            controller.vm.setState({ selectedBankIndex: onChangeValue, disableDepositButton: false }, () => {
                scrollToDepositStep2(enableScrollToDepositStep2, selectedBankIndex, onChangeValue, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);
                if (isQRPay) {
                    let methodObj = methodList[selectedMethodIndex];
                    controller.getCorrectDepositMinMaxLimit(depositAllSettings, methodObj, onChangeValue);
                    controller.getQrPaySupportedBankList(bankObj);
                }

                controller.checkCertainBankRequiredReceipt();
            });
        } else {
            let foundIndex = '';

            if (isQRPay) {
                foundIndex = qrPayList.indexOf(bankObj);
            } else {
                foundIndex = bankList.indexOf(bankObj);
            }

            controller.vm.setState({ selectedBankIndex: foundIndex, disableDepositButton: false }, () => {
                scrollToDepositStep2(enableScrollToDepositStep2, selectedBankIndex, foundIndex, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);
                if (isQRPay) {
                    let methodObj = methodList[selectedMethodIndex];

                    controller.getCorrectDepositMinMaxLimit(depositAllSettings, methodObj, foundIndex);
                    controller.getQrPaySupportedBankList(bankObj);
                }

                controller.checkCertainBankRequiredReceipt();
            });
        }
    },

    getQrPaySupportedBankList(bankObj) {
        const { screen } = controller.vm.props;
        let { depositAllSettings, qrpaySupportedBankCache } = controller.vm.state;
        let qrpaySupportedBankList = [];
        if (qrpaySupportedBankCache[bankObj.code]) {
            qrpaySupportedBankList = qrpaySupportedBankCache[bankObj.code];
        } else {
            // get and cache the list
            if (depositAllSettings.depositJson && depositAllSettings.depositJson.QrSupportedBank && depositAllSettings.depositJson.QrSupportedBank[bankObj.code]) {
                let oriSupportedBank = depositAllSettings.depositJson.QrSupportedBank[bankObj.code];
                for (let i = 0; i < oriSupportedBank.length; i++) {
                    let bankObj = oriSupportedBank[i];
                    bankObj.bankName = window.SPL_Transaction.getBankName(bankObj, window.merchantCode);
                    qrpaySupportedBankList.push(bankObj);
                }

                // append dummy object to make the grid look nice
                if (screen.viewType !== 'mobile' && qrpaySupportedBankList.length % 3 !== 0) {
                    let dummyCount = 3 - (qrpaySupportedBankList.length % 3);
                    for (let i = 0; i < dummyCount; i++) {
                        qrpaySupportedBankList.push({
                            isDummyBank: true,
                        });
                    }
                }
                qrpaySupportedBankCache[bankObj.code] = qrpaySupportedBankList;
            } else {
                qrpaySupportedBankCache[bankObj.code] = [];
            }
            controller.vm.setState({ qrpaySupportedBankCache: qrpaySupportedBankCache });
        }
        controller.vm.setState({ qrpaySupportedBankList: qrpaySupportedBankList });
    },

    toggleQrPayBankList(flag) {
        const { qrpaySupportedBankList } = controller.vm.state;
        if (qrpaySupportedBankList.length > 0) {
            controller.vm.setState({ showQrBankList: flag });
        }
    },

    onDepositAmountClicked(item) {
        let fields = controller.vm.state.fields;
        fields['amount'] = item;
        controller.vm.setState({ fields }, () => {
            controller.handleValidation();
        });
    },

    handleFieldChanged(e, field) {
        let fields = controller.vm.state.fields;
        fields[field] = e.target.value;
        controller.vm.setState({ fields }, () => {
            controller.handleValidation();
        });
    },

    handleValidation() {
        const { depositMinMaxLimit } = controller.vm.state;
        let fields = controller.vm.state.fields;
        let errors = {};
        let isFormValid = true;
        let amountErrorValue = 0;
        if (!fields['amount']) {
            isFormValid = false;
            errors['amount'] = 'transaction:transaction.deposit.onlinetransfer.invalidAmount';
        }

        if (typeof fields['amount'] !== undefined) {
            let amount = Number(fields['amount']);
            if (amount >= 0) {
                if (amount < depositMinMaxLimit.min) {
                    isFormValid = false;
                    errors['amount'] = 'transaction:transaction.deposit.onlinetransfer.invalidMinAmount';
                    amountErrorValue = depositMinMaxLimit.min;
                } else if (amount > depositMinMaxLimit.max) {
                    isFormValid = false;
                    errors['amount'] = 'transaction:transaction.deposit.onlinetransfer.invalidMaxAmount';
                    amountErrorValue = depositMinMaxLimit.max;
                }
            } else {
                isFormValid = false;
                errors['amount'] = 'transaction:transaction.deposit.onlinetransfer.invalidAmount';
            }
        }

        controller.vm.setState({
            errors: errors,
            amountErrorValue,
            isFormValid: isFormValid,
        });
    },

    handleReceiptImageChange(e) {
        if (e && e.target && e.target.files && e.target.files.length > 0) {
            controller.vm.setState(
                {
                    receiptImg: URL.createObjectURL(e.target.files[0]),
                    receiptImgFile: e.target.files[0],
                },
                () => {
                    this.handleReceiptValidation();
                }
            );
        }
    },

    handleReceiptValidation() {
        const { showOnlineBankDetails, depositAllSettings, depositOptions, bankList, selectedMemberBankIndex, isReload } = controller.vm.state;
        const { receiptImg, selectedChannelIndex } = controller.vm.state;
        let channel = depositOptions[selectedChannelIndex];
        let selectedBankObj = null;
        let isReceiptValid = true;
        if ((showOnlineBankDetails || depositAllSettings.showOnlineBankingOnly) && bankList.length > 0) {
            selectedBankObj = bankList[selectedMemberBankIndex];
        }

        if (
            (channel.value === 'D' ||
                channel.value === 'A' ||
                (channel.value === 'O' && depositAllSettings.requiredReceiptForOnlineBanking) ||
                (depositAllSettings.receiptMandatoryBankCodes && selectedBankObj && depositAllSettings.receiptMandatoryBankCodes.indexOf(selectedBankObj.bankCode) !== -1) ||
                isReload) &&
            receiptImg.length === 0
        ) {
            isReceiptValid = false;
            controller.vm.setState({ receiptRequired: true, isReceiptValid: isReceiptValid });
        } else {
            isReceiptValid = true;
            controller.vm.setState({ receiptRequired: false, isReceiptValid: isReceiptValid });
        }

        return isReceiptValid;
    },

    handleUploadReceiptButtonOnClick() {
        let hiddenInput = document.getElementById('hiddenFileInput');
        if (hiddenInput) {
            hiddenInput.click();
        }
    },

    submitForm() {
        const { isFormValid, disableDepositButton, isOnlineTransfer, isQRPay, isReload, isBankApp } = controller.vm.state;

        if (isFormValid && !disableDepositButton) {
            if (isQRPay) {
                controller.submitQrPayDeposit();
            } else if (isReload) {
                controller.submitReloadDeposit();
            } else if (!isOnlineTransfer && !isBankApp) {
                controller.submitThirdPartyDeposit();
            } else {
                // validate form again before proceed

                const isReceiptValid = controller.handleReceiptValidation();
                if (isReceiptValid) {
                    controller.submitBankTransferDeposit();
                }
            }
        }
    },

    submitThirdPartyDeposit() {
        const { methodList, bankList, fields, selectedBankIndex, selectedMethodIndex, filteredDepositApiJson, depositSubmitCountdown } = controller.vm.state;
        const { language, depositSubmission, t } = controller.vm.props;

        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            // controller.vm.setState({ disableDepositButton: true });
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            window.SPL_Content.getPortalSettings().then((settings) => {
                let hideLangPathUrl = false;
                if (settings && settings.features && settings.features.hideLangPathUrl) {
                    hideLangPathUrl = settings.features.hideLangPathUrl;
                }
                let methodObj = methodList[selectedMethodIndex];
                let bankCode = bankList[selectedBankIndex] ? bankList[selectedBankIndex].code : null;
                let amount = fields.amount;
                let langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
                let callbackUrl = `/${langPath}/myaccount/deposit`;
                let stateUrl = '';

                if (hideLangPathUrl) {
                    stateUrl = '/quickpayredirect';
                } else {
                    stateUrl = `/${langPath}/quickpayredirect`;
                }
                window.SPL_Transaction.requestDepositOnlineTransfer(methodObj, bankCode, amount, null, null, stateUrl, language.key, callbackUrl).then((data) => {
                    if (data.errorCode) {
                        notification.showNotification('error', data.message);
                    } else {
                        trans_handlePGDepositCountDown(controller.vm, true, 60, paymentCode, Date.now().valueOf(), { disableDepositButton: false });
                    }
                });
            });
        }
    },

    submitQrPayDeposit() {
        const { qrPayList, fields, selectedBankIndex, filteredDepositApiJson, depositSubmitCountdown } = controller.vm.state;
        const { language, depositSubmission, t } = controller.vm.props;

        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            // controller.vm.setState({ disableDepositButton: true });
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            window.SPL_Content.getPortalSettings().then((settings) => {
                let hideLangPathUrl = false;
                if (settings && settings.features && settings.features.hideLangPathUrl) {
                    hideLangPathUrl = settings.features.hideLangPathUrl;
                }
                let bankObj = qrPayList[selectedBankIndex];
                let amount = fields.amount;
                let langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
                let callbackUrl = `/${langPath}/myaccount/deposit`;
                let stateUrl = '';

                if (hideLangPathUrl) {
                    stateUrl = '/quickpayredirect';
                } else {
                    stateUrl = `/${langPath}/quickpayredirect`;
                }
                let methodParam = {
                    id: bankObj?.id,
                    code: bankObj ? bankObj.code : null,
                };
                window.SPL_Transaction.requestDepositOnlineTransfer(methodParam, methodParam.code, amount, null, null, stateUrl, language.key, callbackUrl).then((data) => {
                    if (data.errorCode) {
                        notification.showNotification('error', data.message);
                    } else {
                        trans_handlePGDepositCountDown(controller.vm, true, 60, paymentCode, Date.now().valueOf(), { disableDepositButton: false });
                    }
                });
            });
        }
    },

    removeBankList(memberBank) {
        memberBank.preferredBank = false;

        window.SPL_Member.updatePreferredBank(memberBank).then((yes) => {
            controller.vm.setState({ expressDepositMemberBankList: null, expressDepositMerchantBankList: null, showAllBankMaintenaceMsg: false });
            controller.loadExpressDepositFilteredBank();
        });
    },

    handleSubmitExpressDepositBank() {
        const { verifyAccountList } = controller.vm.state;
        let isFormValid = false;

        controller.resetForm();
        //set selected bank as preferred bank
        verifyAccountList.preferred = true;

        window.SPL_Member.updatePreferredBank(verifyAccountList).then((yes) => {
            controller.vm.setState({
                expressDepositMemberBankList: verifyAccountList,
                isFormValid: isFormValid,
                selectedChannel: ' ',
                showAllBankMaintenaceMsg: false,
                displayDefault: true,
            });
            controller.loadExpressDepositFilteredBank(verifyAccountList);
        });
    },

    submitBankTransferDeposit() {
        controller.vm.setState({ disableDepositButton: true });
        const { selectedBankIndex, selectedChannelIndex, selectedBankDetailIndex } = controller.vm.state;
        const { receiptImgFile, depositOptions, bankList, memberBankList } = controller.vm.state;
        const { fields } = controller.vm.state;

        let channelObj = depositOptions[selectedChannelIndex];
        let merchantBankObj = bankList[selectedBankIndex];
        let merchantBankId = merchantBankObj?.id;
        let memberBankDetails = null;

        if (memberBankList[selectedBankDetailIndex] && memberBankList[selectedBankDetailIndex].memberBankId) {
            memberBankDetails = memberBankList[selectedBankDetailIndex].memberBankId;
        }

        let picFile = receiptImgFile;

        window.SPL_Transaction.localPaySubmit(null, null, merchantBankId, fields.amount, channelObj.value, null, memberBankDetails, picFile, fields.reference).then((data) => {
            if (data.status === 'S') {
                controller.resetForm();
                controller.vm.setState({ disableDepositButton: false });
                notification.showNotification('success', controller.vm.props.t('transaction:transaction.deposit.request-success'));
            } else {
                let errorMsg = '';
                if (data.code === 'receipt_fail') {
                    errorMsg = 'transaction:transaction.withdrawal.request-uploadReceiptError';
                } else if (data.code === 'fail_deposit' || data.msg.data.status === 409 || data.msg.data.status === 403 || data.msg.data.status === 500) {
                    errorMsg = 'transaction:transaction.withdrawal.request-pending';
                } else {
                    errorMsg = 'transaction:transaction.withdrawal.request-error';
                }
                controller.vm.setState({ disableDepositButton: false });
                notification.showNotification('error', controller.vm.props.t(errorMsg));
            }
        });
    },

    changeCryptoChannel(e, screen) {
        let value = '';

        if (screen === 'mobile') {
            value = e;
        } else {
            value = e.target.value;
        }

        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
        controller.vm.setState({ selectedCryptoIndex: value, selectedChainIndex: disablePreselectBank ? null : 0 }, () => {
            let crypto = controller.vm.state.methodList.filter((data) => data.key === 'crypto');

            controller.onMethodListChanged(crypto[0], true);
        });
    },

    onChainChanged(chainObj) {
        core.portalIsLoading(controller.vm.props, true);

        const { selectedChainIndex, cryptoChain } = controller.vm.state;
        let foundIndex = cryptoChain.indexOf(chainObj);

        if (foundIndex !== selectedChainIndex) {
            controller.vm.setState({ selectedChainIndex: foundIndex, disableDepositButton: false }, () => {
                const {
                    enableScrollToDepositStep2 = false,
                    scrollToDepositStep2Attempts = 15,
                    scrollToDepositStep2Delay = 200,
                } = controller.vm.props.portal?.settings?.features?.depositSettings || {};
                scrollToDepositStep2(enableScrollToDepositStep2, selectedChainIndex, foundIndex, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);
                controller.checkChainOptions();
            });
        }
    },

    checkChainOptions() {
        const { account } = controller.vm.props.user;

        let userValidate = window.SPL_Member.toShowMemberVerification(account);

        if (userValidate) {
            let showDialog = userValidate.showDialog,
                showEmail = userValidate.showEmail,
                showName = userValidate.showName,
                showPhone = userValidate.showPhone,
                showDob = userValidate.showDob;

            if (showDialog) {
                core.portalIsLoading(controller.vm.props, false);
                controller.getCountryInfo();
                controller.vm.setState({ memberVerifyPopup: showDialog, showEmail: showEmail, showName: showName, showPhone: showPhone, showDob: showDob });
            } else {
                controller.getDepositDisplaySetting();
                controller.getQrCode();
            }
        }
    },

    getQrCode() {
        const { countryLanguageKey } = controller.vm.props.language;
        const { currency } = controller.vm.props.user.account;
        const { selectedChainIndex, cryptoChain } = controller.vm.state;

        let qrParams = {
            bank: cryptoChain[selectedChainIndex]?.bankCode,
            methodCode: cryptoChain[selectedChainIndex]?.bankCode,
            merchantCode: window.merchantCode,
            portalLanguage: countryLanguageKey,
            merchantBankId: cryptoChain[selectedChainIndex]?.id,
        };

        window.SPL_Transaction.getDepositQr(qrParams, currency).then((data) => {
            if (data && data.cryptoDetail) {
                controller.vm.setState({
                    cryptoQrLink: data.cryptoDetail.link,
                    cryptoQrCode: data.cryptoDetail.address,
                    selectedChainMin: data.min,
                    exchangeRate: data.cryptoDetail.exchangeRate,
                    chainBranch: cryptoChain[selectedChainIndex]?.branch,
                    chainName: cryptoChain[selectedChainIndex]?.province,
                });
                core.portalIsLoading(controller.vm.props, false);
            }
        });
    },

    qrcode() {
        const { cryptoQrCode } = controller.vm.state;
        return cryptoQrCode;
    },

    downloadQrCode() {
        const { chainName } = controller.vm.state;

        const qrCodeURL = document.getElementById('cryptoQR').toDataURL('image/png').replace('image/png', 'image/octet-stream');
        let aEl = document.createElement('a');
        aEl.href = qrCodeURL;
        aEl.download = chainName + '.png';
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    },

    getShareInfo() {
        controller.vm.setState({ getShareInfo: true });
    },

    getCryptoQrCode(param) {
        window.open(param);
    },

    getDepositDisplaySetting() {
        controller.vm.setState({ chainRemark: [] }, () => {
            const { countryLanguageKey } = controller.vm.props.language;
            const { selectedChainIndex, cryptoChain } = controller.vm.state;
            let bankCode = cryptoChain[selectedChainIndex]?.bankCode;

            window.SPL_Transaction.getDepositDisplaySetting().then((data) => {
                if (data && data.remarkNotice && data.remarkNotice[countryLanguageKey] && data.remarkNotice[countryLanguageKey][bankCode]) {
                    controller.vm.setState({ chainRemark: data.remarkNotice[countryLanguageKey][bankCode] });
                }
            });
        });
    },

    close(type) {
        if (type === 'memberVerify') {
            controller.vm.setState({ memberVerifyPopup: false });
        } else if (type === 'shareInfo') {
            controller.vm.setState({ getShareInfo: false });
        } else if (type === 'addBank') {
            controller.vm.setState({ addBankDialogIsShow: false });
            controller.loadBankAccounts();
        }
    },

    handleCryptoFieldChanged(e, field) {
        const { cryptoFields, errors, telCodeList } = controller.vm.state;

        if (field === 'telCode') {
            if (cryptoFields[field] !== e) {
                cryptoFields[field] = e.target.value;
                for (let i = 0; i < telCodeList.length; i++) {
                    if (`+${telCodeList[i].telCode}` === cryptoFields[field]) {
                        cryptoFields['currency'] = telCodeList[i].currency;
                        controller.vm.setState({ cryptoFields });
                    }
                }
            }
        } else {
            cryptoFields[field] = field === 'dob' ? e : e.target.value;
            controller.vm.setState({ cryptoFields });
        }

        if (field === 'fullname' && typeof cryptoFields['fullname'] !== undefined) {
            let fullname = cryptoFields['fullname'];
            window.SPL_Register.validateFullName(fullname, window.merchantCode).then((errMsg) => {
                controller.handleInputError(errMsg, 'fullname', errors);
            });
        }

        if (field === 'email' && typeof cryptoFields['email'] !== undefined) {
            let email = cryptoFields['email'];
            window.SPL_Register.validateEmail(email, window.merchantCode).then((errMsg) => {
                controller.handleInputError(errMsg, 'email', errors);
            });
        }

        if (field === 'phone' && typeof cryptoFields['phone'] !== undefined) {
            let phone = cryptoFields['phone'];
            let telCode = cryptoFields['telCode'];
            window.SPL_Register.validatePhone(phone, window.merchantCode, telCode).then((errMsg) => {
                controller.handleInputError(errMsg, 'phone', errors);
            });
        }

        if (typeof cryptoFields['dob'] !== undefined) {
            let dob = cryptoFields['dob'];
            let errMsg = [];
            if (dob === undefined || dob === null) {
                errMsg.push('global:global.form.dob-placeholder');
            }
            controller.handleInputError(errMsg, 'dob', errors);
        }
    },

    handleInputError(errMsg, field, errors) {
        const { showName, fullname, showPhone, phone, showEmail, email } = controller.vm.state.cryptoFields;
        let memberVerifyDisable = true;

        if (errMsg.length > 0) {
            errors[field] = errMsg[0];
        } else {
            errors[field] = '';
        }

        if ((showName && !fullname) || (showPhone && !phone) || (showEmail && !email) || errors['fullname'] || errors['phone'] || errors['email']) {
            memberVerifyDisable = true;
        } else {
            memberVerifyDisable = false;
        }

        controller.vm.setState({ errors, memberVerifyDisable: memberVerifyDisable });
    },

    getCountryInfo() {
        window.SPL_Content.getCountryInfo().then((telCodeList) => {
            const { language } = controller.vm.props;
            controller.vm.setState({ telCodeList: telCodeList });
            controller.getOptionList(telCodeList, language.country);
        });
    },

    getOptionList(telCodeList, country) {
        let obj = {
            telCode: [],
        };
        let fields = controller.vm.state.cryptoFields;

        for (let i = 0; i < telCodeList.length; i++) {
            obj.telCode.push('+' + telCodeList[i].telCode);

            if (telCodeList[i].country === country) {
                fields.telCode = '+' + telCodeList[i].telCode;
            }

            controller.vm.setState({ cryptoFields: fields });
        }

        controller.vm.setState({ optionsList: obj });

        return obj;
    },

    handleMemberVerifySubmit() {
        const { id, login } = controller.vm.props.user.account;
        const { fullname, telcode, phone, email, dob } = controller.vm.state.cryptoFields;
        let params = {
            id: id,
            login: login,
            name: fullname,
            phone: telcode + phone,
            email: email,
            dob: dob,
        };

        window.SPL_Member.updateMemberDetails(params).then((data) => {
            if (data) {
                if (data.status === 'S') {
                    controller.vm.setState({ memberVerifyPopup: false }, () => {
                        core.portalIsLoading(controller.vm.props, true);
                        controller.getQrCode();
                    });
                } else {
                    controller.vm.setState({ requestRefill: true, memberVerifyDisable: true });
                }
            }
        });
    },

    handleAddBankChange(e, field) {
        let accountBankfields = this.vm.state.accountBankfields;
        let val = e.target.value;

        accountBankfields[field] = e.target.value;
        this.vm.setState({ accountBankfields }, () => {
            if (field === 'bankName') {
                this.validateBankName();
            }
            if (field === 'accountNumber') {
                if (this.isNumber(val)) {
                    this.vm.setState({ errors: { accountNumber: '' } });
                } else {
                    this.vm.setState({ errors: { accountNumber: 'transaction:transaction.deposit.addBank.accountNumberError' } });
                }
            }
        });
    },

    validateBankName() {
        window.SPL_Transaction.validateBankName(this.vm.state.accountBankfields.bankName).then((errMsg) => {
            this.vm.setState({ bankNameErrMsg: errMsg });
        });
    },

    isNumber(val) {
        return /^\d*$/.test(val);
    },

    handdleAddAccountBankSubmit() {
        const { t } = controller.vm.props;
        let accountBankfields = this.vm.state.accountBankfields;
        let selectedBankIndex = accountBankfields['selectedBankIndex'];
        let accountName = accountBankfields['accountName'];
        let accountNumber = accountBankfields['accountNumber'];
        let bankName = accountBankfields['bankName'];
        let bankBranch = accountBankfields['bankBranch'];
        let bankObj = this.vm.state.unOwnedBankList[selectedBankIndex];
        this.vm.setState({ statusMsg: '' });

        window.SPL_Member.addBankAcc(accountName, accountNumber, bankBranch, bankObj?.bankId, bankName).then((data) => {
            if (data.status === 'S') {
                controller.close('addBank');
            } else {
                if (data.detail.toLowerCase() === 'bank account already in use') {
                    notification.showNotification('error', t('global:global.verification.duplicateBankAcc', 'Bank account is already in use'), { autoClose: 2000 });
                } else {
                    notification.showNotification('error', controller.vm.props.t(data.detail), { autoClose: 2000 });
                }
            }
        });
    },

    getReloadDate(date) {
        controller.vm.setState({ reload_date: date });
    },

    isValidForm() {
        const { bankNameErrMsg, errors, showBankName, accountBankfields } = controller.vm.state;
        if (showBankName && (accountBankfields['bankName'] === null || accountBankfields['bankName'] === '')) {
            return false;
        } else if (accountBankfields['accountNumber'] === null || accountBankfields['accountNumber'] === '') {
            return false;
        } else {
            let formValid =
                bankNameErrMsg.length === 0 &&
                (typeof errors.accountNumber === 'undefined' || errors.accountNumber.length === 0) &&
                accountBankfields['accountNumber'].length > 0 &&
                ((showBankName && accountBankfields['bankName']) || !showBankName);
            return formValid;
        }
    },

    submitReloadDeposit() {
        const { reloadDetails, receiptImgFile, reload_date, fields } = controller.vm.state;
        let date = moment(reload_date).format('YYYY-MM-DD HH:mm');

        window.SPL_Transaction.validateBeforeSubmit(
            reloadDetails.blockProviders,
            reloadDetails.preferredWallet,
            receiptImgFile,
            fields.reference,
            date,
            fields.amount,
            reloadDetails.bank,
            reloadDetails.bank.id
        ).then((data) => {
            controller.vm.setState({ disableDepositButton: false });

            if (data.msg && data.msg[0] !== 'Success') {
                notification.showNotification('error', controller.vm.props.t(data.msg[0]));
            } else {
                notification.showNotification('success', controller.vm.props.t('transaction:transaction.deposit.request-success'));
            }
        });
    },

    loadBankAccounts() {
        let loadAllOwnedAndUnOwnedBank = new Promise((resolve) => {
            window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => resolve(data));
        });
        let loadGetVerifiedBankAccountsByType = new Promise((resolve) => {
            window.SPL_Member.getVerifiedBankAccountsByType().then((value) => resolve(value));
        });

        Promise.all([loadAllOwnedAndUnOwnedBank, loadGetVerifiedBankAccountsByType]).then((result) => {
            let ownedBankList = result[0];
            let verifyMemberBank = result[1];

            let memberBankList = ownedBankList.memberBankList;
            let bankList = ownedBankList.bankList;
            let verifyBank = [];
            for (let i = 0; i < memberBankList.length; i++) {
                for (let j = 0; j < bankList.length; j++) {
                    const memberBank = memberBankList[i];
                    const bankListName = bankList[i];
                    if (bankListName) {
                        memberBank.displayName = `${memberBank.bankName} ${memberBank.accountNumber} ${memberBank.accountHolder}`;
                    }
                }
            }

            for (let j = 0; j < verifyMemberBank.length; j++) {
                if (verifyMemberBank[j] && verifyMemberBank[j].verified) {
                    verifyBank.push(verifyMemberBank[j]);
                }
            }

            controller.vm.setState({
                memberBankList: ownedBankList.memberBankList,
                unOwnedBankList: ownedBankList.unOwnedBankList,
                verifyBank: verifyBank,
            });
        });
    },

    loadExpressDepositFilteredBank() {
        window.SPL_Member.getExpressDepositFilteredBank().then((res) => {
            let merchantBank = res.data.merchantBankList;
            let memberBank = res.data.memberBankList;

            let expressDepositBankList = [];
            let expressDepositMerchantBankList = [];

            let showNotBankSupportMsg = merchantBank.length <= 0;
            let showAllBankMaintenaceMsg = memberBank.length <= 0;

            if (memberBank.length >= 1) {
                for (let i = 0; i < memberBank.length; i++) {
                    expressDepositBankList = controller.copyMemberBank(memberBank[i], true);
                }
            }

            if (merchantBank.length >= 1) {
                for (let j = 0; j < merchantBank.length; j++) {
                    expressDepositMerchantBankList.push(merchantBank[j]);
                }
            }

            controller.vm.setState({
                expressDepositMemberBankList: expressDepositBankList,
                expressDepositMerchantBankList: expressDepositMerchantBankList,
                showAllBankMaintenaceMsg: showAllBankMaintenaceMsg,
                showNotBankSupportMsg: showNotBankSupportMsg,
            });
        });
    },

    copyMemberBank(memberBankList, isMerchantBank) {
        const { country } = controller.vm.props.language;
        if (isMerchantBank) {
            memberBankList.bankName = window.SPL_Translation.getShorterBankName(memberBankList.bankCode, country);
        } else {
            memberBankList.bankName = window.SPL_Translation.getShorterBankName(memberBankList.bankCode, country);
        }

        return {
            id: memberBankList.id,
            name: memberBankList.bankName,
            accountHolder: memberBankList.accountName,
            accountNumber: memberBankList.accountNumber,
            accountNumberDisplay: memberBankList.accountNumber,
            bankCode: memberBankList.bankCode,
            isChecked: false,
            bankName: memberBankList.bankName,
        };
    },

    handleBankChange(e) {
        let accountBankfields = this.vm.state.accountBankfields;
        let showBankName = false;
        let bankObj = this.vm.state.unOwnedBankList[e.target.value];
        if (bankObj.bankId === 0 || bankObj.bankId === '0' || bankObj.bankId === 'o') {
            showBankName = true;
        }

        accountBankfields.selectedBankIndex = e.target.value;

        this.vm.setState({ showBankName: showBankName });
    },

    checkCertainBankRequiredReceipt() {
        const { depositAllSettings, methodList, selectedMethodIndex, bankList, selectedBankIndex, memberBankList, selectedBankDetailIndex } = controller.vm.state;
        const currency = controller.vm.props.user.account.currency;
        let requiredReceiptForCertainOnlineBanking = depositAllSettings.requiredReceiptForCertainOnlineBanking;
        let bankSelected = {};
        let memberBankSelected = {};

        if (bankList && bankList.length > 0) {
            bankSelected = bankList[selectedBankIndex]?.bankCode;
        }

        if (memberBankList && memberBankList.length > 0) {
            if (memberBankList[selectedBankDetailIndex]) {
                memberBankSelected = memberBankList[selectedBankDetailIndex].bankCode.toUpperCase();
            }
        }

        if (requiredReceiptForCertainOnlineBanking && methodList && methodList[selectedMethodIndex] && methodList[selectedMethodIndex].isOnlineTransfer) {
            window.SPL_Transaction.checkCertainBankRequiredReceipt(
                currency,
                requiredReceiptForCertainOnlineBanking,
                bankSelected,
                memberBankSelected,
                controller.handleReceiptValidation
            ).then((res) => {
                controller.vm.setState({ receiptRequired: res });
            });
        }
    },
};

export default controller;
