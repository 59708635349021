// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { DebounceInput } from 'react-debounce-input';
import DatePicker from 'react-datepicker';
import { AiOutlineCopy } from 'react-icons/ai';
import { EmailIcon, EmailShareButton, LineIcon, LineShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';

// components/files
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';
import QRCode from 'qrcode.react';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';

// controller/logic
import vm from '../../crypto.controller';
import core from 'root/utils/core';
import transactionHelper from 'root/utils/transaction-helper';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import { CryptoBankIcon } from '../../../../../../../../components/molecules/bank-icon/bank-icon';
import TransactionNote from '../../../../../../../shared/transaction-note/component/transaction-note';
import isNil from 'lodash/isNil';
class Crypto extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.getPaymentGatewaySetting().then(() => {
                vm.loadDepositDetails();
            });
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const prevDepositAmountOptions = JSON.stringify(trans_getDepositAmountOptionByMethod(prevProps.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.CT));
        const currentDepositAmountOptions = JSON.stringify(this.state.depositAmountOptions);
        if (prevDepositAmountOptions !== currentDepositAmountOptions) {
            this.setState({ depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.CT) });
        }
        if (prevState.depositMinMaxLimit?.min !== this.state.depositMinMaxLimit?.min) {
            vm.readCustomDepositArticle();
            if (this.state.isAmountDirty) vm.validateDepositAmount();
        }
    }

    onSubmitFormClick() {
        vm.submitForm();
    }
    onHandleAmountChange(value, field) {
        vm.handleAmountChange(value, field);
    }

    onSharedAddressAmountChange(e, item) {
        this.onHandleAmountChange(e.target.value, 'amount');
        transactionHelper.onDepositAmountClicked(this, item);
    }

    render() {
        const {
            cryptoQrLink,
            memberVerifyPopup,
            getShareInfo,
            cryptoChain,
            selectedChainIndex,
            cryptoOptions,
            selectedCryptoIndex,
            cryptoQrCode,
            chainName,
            chainBranch,
            exchangeRate,
            chainRemark,
            coinAmountDisable,
            depositMinMaxLimit,
            fields,
            errors,
            disableDepositButton,
            amountErrorValue,
            depositAmountOptions,
            isFormValid,
            isLoading,
        } = this.state;
        const { user } = this.props;

        let userCurrency = user && user.account && user.account.currency ? user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        const disablePreselectBank = !!this.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;

        let transactionNoteProps = {
            customFilename: this.state.customFilename,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            depositMinMaxLimit: this.state.depositMinMaxLimit,
        };

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        {memberVerifyPopup && (
                            <div
                                className='memberVerify-box'
                                onClick={() => {
                                    transactionHelper.close(this, 'memberVerify');
                                }}
                            >
                                <div
                                    className='memberVerify-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.crypto.memberVerify', 'Member Information Verification')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                transactionHelper.close(this, 'memberVerify');
                                            }}
                                        ></i>
                                    </div>
                                    <div className='content'>
                                        {this.state.showName && (
                                            <div className='input'>
                                                <DebounceInput
                                                    type='text'
                                                    debounceTimeout='1000'
                                                    values={this.state.cryptoFields['fullname']}
                                                    placeholder={t('global:global.form.fullname', 'Full Name')}
                                                    onChange={(e) => {
                                                        vm.handleCryptoFieldChanged(e, 'fullname');
                                                    }}
                                                />
                                                <div className='input-error'>
                                                    {this.state.errors['fullname'] !== 'global:global.form.fullname-placeholderv2' && t(this.state.errors['fullname'])}
                                                </div>
                                            </div>
                                        )}

                                        {this.state.showEmail && (
                                            <div className='input'>
                                                <DebounceInput
                                                    type='text'
                                                    debounceTimeout='1000'
                                                    values={this.state.fields['email']}
                                                    placeholder={t('global:global.form.email', 'Email Address')}
                                                    onChange={(e) => {
                                                        vm.handleCryptoFieldChanged(e, 'email');
                                                    }}
                                                />
                                                <div className='input-error'>{t(this.state.errors['email'])}</div>
                                            </div>
                                        )}

                                        {this.state.showPhone && (
                                            <div className='input'>
                                                <div>
                                                    <select
                                                        className='phone'
                                                        value={this.state.fields['telCode']}
                                                        onChange={(e) => {
                                                            vm.handleCryptoFieldChanged(e, 'telCode');
                                                        }}
                                                    >
                                                        {this.state.optionsList['telCode'].map((option, i) => (
                                                            <option key={i} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <DebounceInput
                                                        type='text'
                                                        debounceTimeout='1000'
                                                        values={this.state.fields['phone']}
                                                        placeholder={t('global:global.form.phone', 'Telephone Number')}
                                                        onChange={(e) => {
                                                            vm.handleCryptoFieldChanged(e, 'phone');
                                                        }}
                                                        className='short'
                                                    />
                                                </div>
                                                <div className='input-error'>{t(this.state.errors['phone'])}</div>
                                            </div>
                                        )}

                                        {this.state.showDob && (
                                            <div className='input'>
                                                <div>
                                                    <span className='item-title'>{t('global:global.form.dob', 'Date of Birth')}</span>
                                                    <DatePicker
                                                        autoComplete='off'
                                                        type='text'
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        placeholderText={t('transaction:transaction.deposit.cashdeposit.depositdate', 'Deposit Date/Time')}
                                                        selectsStart
                                                        selected={this.state.cryptoFields.dob}
                                                        maxDate={this.state.maxDob}
                                                        popperPlacement='bottom-end'
                                                        onChange={(e) => {
                                                            vm.handleCryptoFieldChanged(e, 'dob');
                                                        }}
                                                        timeFormat='yyyy-MM-dd'
                                                        dateFormat='yyyy-MM-dd'
                                                        className='short'
                                                    />
                                                </div>
                                                <div className='input-error'>{t(this.state.errors['dob'])}</div>
                                            </div>
                                        )}

                                        {this.state.requestRefill && (
                                            <div className='input'>
                                                <div className='input-error'>{t('transaction:transaction.deposit.crypto.fillAgain', 'Please fill in again.')}</div>
                                            </div>
                                        )}

                                        <button className='btn-submit' disabled={this.state.memberVerifyDisable} onClick={() => vm.handleMemberVerifySubmit()}>
                                            {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {getShareInfo && (
                            <div
                                className='shareInfo-box mobile-box'
                                onClick={() => {
                                    transactionHelper.close(this, 'shareInfo');
                                }}
                            >
                                <div
                                    className='shareInfo-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('settings:settings.inviteFriend', 'Invite Friend')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                transactionHelper.close(this, 'shareInfo');
                                            }}
                                        ></i>
                                    </div>
                                    <div className='content shareInfo-content'>
                                        <EmailShareButton url={cryptoQrCode} className='shareInfo-link'>
                                            <EmailIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>Email</span>
                                        </EmailShareButton>
                                        <WhatsappShareButton url={cryptoQrCode} className='shareInfo-link'>
                                            <WhatsappIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>Whatsapp</span>
                                        </WhatsappShareButton>
                                        <TelegramShareButton url={cryptoQrCode} className='shareInfo-link'>
                                            <TelegramIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>Telegram</span>
                                        </TelegramShareButton>
                                        <LineShareButton url={cryptoQrCode} className='shareInfo-link'>
                                            <LineIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>LINE</span>
                                        </LineShareButton>
                                        <button
                                            className='shareInfo-copy'
                                            onClick={() => {
                                                core.copy(t, cryptoQrCode);
                                            }}
                                        >
                                            <AiOutlineCopy></AiOutlineCopy> <span>Copy to Clipboard</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {cryptoOptions && cryptoOptions.length > 1 && (
                            <MobileSelect
                                itemList={cryptoOptions}
                                itemKey={'channelIndex'}
                                onChange={(e) => vm.changeCryptoChannel(e, 'mobile')}
                                itemIndex={selectedCryptoIndex}
                                title={t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}
                                required={true}
                                onChangeIndexType={true}
                                disablePreselectBank={disablePreselectBank}
                            />
                        )}

                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.crypto.cryptoCurrency', 'Crypto Currency')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='bank-list bank-selector'>
                                <ul className='bank-list'>
                                    {cryptoChain &&
                                        cryptoChain.length > 0 &&
                                        cryptoChain.map((chain, i) => (
                                            <li
                                                key={i}
                                                className='small-bank bank-acc-list'
                                                onClick={() => {
                                                    vm.onChainChanged(chain);
                                                }}
                                            >
                                                <div className='member-bankacc'>
                                                    <label>
                                                        <input
                                                            type='radio'
                                                            name='bank-account'
                                                            value='0'
                                                            checked={selectedChainIndex === i}
                                                            onChange={() => {
                                                                vm.onChainChanged(chain);
                                                            }}
                                                        />
                                                        <span className='checkmark'></span>
                                                        <div className='banklist-group'>
                                                            <div className='group-box'>
                                                                <div className={`bank-img crypto-bank-img`}>
                                                                    <div className={['icon-bank', 'icon-bank-big', selectedChainIndex === i ? 'on' : ''].join(' ')}>
                                                                        <CryptoBankIcon bankCode={`${chain.coin}_${chain.network}`} coin={chain.coin} />
                                                                    </div>
                                                                    <div className='bank-active'></div>
                                                                </div>
                                                                <div className='bank-name'>{chain.displayName}</div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>

                        {!cryptoQrCode && !coinAmountDisable && !isLoading && !isNil(selectedChainIndex) ? (
                            <>
                                <div className='group-item'>
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                        <span className='required'>*</span>
                                    </div>
                                    <div className='amount-content'>
                                        <div className='input-box'>
                                            <input
                                                id='depositamount'
                                                type='numeric'
                                                autoComplete='off'
                                                placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                    min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                                    max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                                })}
                                                min={'0'}
                                                value={this.state.fields['amount']}
                                                onChange={(e) => this.onHandleAmountChange(e.target.value, 'amount')}
                                                onBlur={() => {
                                                    transactionHelper.handleValidation(this);
                                                }}
                                            />
                                            <div className='error-validation'>
                                                <Trans
                                                    i18nKey={this.state.errors['amount']}
                                                    values={{ amount: window.SPL_Other.formatAmount(this.state.amountErrorValue), currency: userCurrency }}
                                                ></Trans>
                                            </div>
                                        </div>
                                        <ul>
                                            {depositAmountOptions.map((item) => (
                                                <li
                                                    key={item}
                                                    onClick={(e) => {
                                                        this.onSharedAddressAmountChange(e, item);
                                                    }}
                                                >
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        ) : coinAmountDisable && !isLoading && !isNil(selectedChainIndex) ? (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.coinAmount', 'Coin Amount')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content special-group'>
                                    <div className='input-box'>
                                        <input
                                            id='depositamount'
                                            type='numeric'
                                            autoComplete='off'
                                            placeholder={
                                                t('transaction:transaction.deposit.crypto.minLimit', 'Min Limit') +
                                                ': ' +
                                                cryptoChain[selectedChainIndex]?.coin +
                                                ' ' +
                                                window.SPL_Other.formatAmount(depositMinMaxLimit.min)
                                            }
                                            min={'0'}
                                            value={fields['amount']}
                                            onChange={(e) => this.onHandleAmountChange(e.target.value, 'amount')}
                                        />
                                        <div className='error-validation'>
                                            <Trans
                                                i18nKey={errors['amount']}
                                                values={{ amount: window.SPL_Other.formatAmount(amountErrorValue), currency: userCurrency }}
                                            ></Trans>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : cryptoQrCode ? (
                            <>
                                <div className='group-item bank-options'>
                                    <div className='bank-list bank-selector crypto-qr' id='crypto-qr'>
                                        <ul className='bank-list'>
                                            {cryptoQrCode && (
                                                <>
                                                    <li className='qr-box'>
                                                        <QRCode id='cryptoQR' value={vm.qrcode()} size={218} className='crypto-qr-img' />
                                                    </li>

                                                    <li className='box-info'>
                                                        <span onClick={() => vm.downloadQrCode()}>{t('transaction:transaction.deposit.crypto.saveQr', 'SAVE QR CODE')}</span>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                <div className='group-item deposit-options crypto-shareBox'>
                                    <div className='title'>
                                        <span>
                                            {t('transaction:transaction.deposit.crypto.sendToChainAddress', 'or send to this Chain address', {
                                                chain: chainName,
                                            })}
                                        </span>
                                    </div>
                                    <ul>
                                        <li className='box-code'>
                                            <p>{cryptoQrCode}</p>
                                        </li>
                                        <li className='btn-share'>
                                            <p onClick={() => vm.getShareInfo()}>{t('transaction:transaction.deposit.crypto.share', 'SHARE')}</p>
                                        </li>

                                        {cryptoQrLink && (
                                            <button className='checkStatus' onClick={() => vm.getCryptoQrCode(cryptoQrLink)}>
                                                {t('transaction:transaction.checkStatus', 'Check Status')}
                                            </button>
                                        )}
                                        {cryptoQrCode && (
                                            <li className='box-info'>
                                                <p>
                                                    {chainName !== 'BTC' && `CHAIN : ${chainBranch} `}{' '}
                                                    {t('transaction:transaction.deposit.crypto.currentRate', 'Current exchange rate')}{' '}
                                                    <span>
                                                        {chainName} 1 = {userCurrency} {exchangeRate.toFixed(2)}
                                                    </span>
                                                </p>
                                            </li>
                                        )}
                                        <li className='info-note'>
                                            <p>
                                                {t(
                                                    'transaction:transaction.deposit.crypto.cryptoDepositNote1',
                                                    'Note: The current exchange rate above is FOR REFERENCE ONLY.'
                                                )}
                                            </p>
                                            <p>
                                                {t(
                                                    'transaction:transaction.deposit.crypto.cryptoDepositNote2',
                                                    'For the most accurate exchange rates, please refer to official {{chain}} exchange rates.',
                                                    {
                                                        chain: this.state.chainName,
                                                    }
                                                )}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        ) : (
                            isLoading && <SmallLoading></SmallLoading>
                        )}

                        {chainRemark && chainRemark.length > 0 && (
                            <div className='notice-box '>
                                <span className='item-title'>{t('transaction:transaction.deposit.crypto.reminder', 'REMINDER')} :</span>
                                <ul className='crypto-remark'>
                                    {chainRemark.map((info, index) => (
                                        <li key={index}>{info}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {!cryptoQrCode && (
                            <div className='group-item submit-container'>
                                <div className='title'></div>
                                <div className='amount-content'>
                                    <div className='flexbox'>
                                        <button
                                            className='btn btn-submit'
                                            onClick={() => {
                                                this.onSubmitFormClick();
                                            }}
                                            disabled={disableDepositButton || !isFormValid}
                                        >
                                            {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <TransactionNote {...transactionNoteProps} />
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(Crypto)));
