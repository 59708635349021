import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import SVGElements from 'root/components/SVGElements';

import vm from '../login.controller';
import core from 'root/utils/core';

import TelegramButton from 'root/webapp/portal/telegram/telegram-button';
import Turnstile from 'react-turnstile';
class FloatingLogin extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        let loginElem = document.getElementById('login');
        loginElem.focus();
        vm.checkIfTurnstileValid(this.state.captchaToken, 'LOGIN');
        vm.initCloudflareSettings();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const telegramFrameUrl = this.props.portal?.settings?.telegram?.telegramFrameUrl;
        const enabledTurnstile = vm.enabledTurnstile('LOGIN');
        return (
            <Translation>
                {(t) => (
                    <div
                        className='login-page-box'
                        onClick={() => {
                            this.props.close();
                        }}
                    >
                        <div
                            className='login-popup'
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <i
                                className='icon-close2'
                                onClick={() => {
                                    this.props.close();
                                }}
                            ></i>
                            <i className='icon-logo2'></i>
                            <div>
                                <div className='input-item'>
                                    <i className='icon-username'></i>
                                    <div className='input'>
                                        <input
                                            id='login'
                                            name='login'
                                            type='text'
                                            value={this.state.fields['login']}
                                            onChange={(e) => {
                                                vm.handleFieldChanged(e, 'login');
                                            }}
                                            placeholder={t('global:global.form.username-placeholder', 'Username')}
                                            onKeyPress={(e) => {
                                                vm.handleKeyPress(e);
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                <div className='input-item'>
                                    <i className='icon-lock'></i>
                                    <div className='input'>
                                        <input
                                            id='password'
                                            name='password'
                                            type='password'
                                            value={this.state.fields['password']}
                                            onChange={(e) => {
                                                vm.handleFieldChanged(e, 'password');
                                            }}
                                            placeholder={t('global:global.form.newpassword-placeholder', 'Password')}
                                            onKeyPress={(e) => {
                                                vm.handleKeyPress(e);
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                {enabledTurnstile && (
                                    <Turnstile
                                        theme={'light'}
                                        language={this.props?.language?.key || 'en'}
                                        className='turnstile-wrapper'
                                        sitekey={this.props?.systemFeatureReducer?.siteKey}
                                        onVerify={(token) => {
                                            vm.handleTurnStileToken(token);
                                        }}
                                        onError={() => {
                                            vm.turnstileErrorHandling();
                                        }}
                                        retry='auto'
                                    />
                                )}
                                <div className='btn-item'>
                                    <span onClick={() => this.props.openResetPasswordPage()}>{t('mobile:mobile.forgetPassword', 'Forget Password?')}</span>
                                    <button
                                        className='btn-login'
                                        onClick={() => {
                                            vm.login();
                                        }}
                                        disabled={this.state.turnstileValidation}
                                    >
                                        {t('global:global.menu.account.login', 'LOGIN')}
                                    </button>
                                </div>
                            </div>

                            <div className='msg-error'>
                                <div className='msg-error-div'>
                                    {this.state.errorMsg && (
                                        <>
                                            <SVGElements className='notice-icon' name='notice-icon' />
                                            {/* <p>{t(this.state.errorMsg)}</p> */}
                                            <p dangerouslySetInnerHTML={{ __html: t(this.state.errorMsg) }}></p>
                                        </>
                                    )}
                                </div>
                            </div>

                            {telegramFrameUrl && <TelegramButton action={'login'} breakLinePosition={'top'} />}

                            <div className='msg-box'>
                                <p className='msg-tips'>
                                    {t('global:global.login-popup.no-account-desc1', 'Do not have an account yet? Click ')}
                                    <span
                                        onClick={() => {
                                            this.props.goToRegister();
                                        }}
                                    >
                                        {t('global:global.login-popup.link-join-now', 'here')}
                                    </span>
                                    {t('global:global.login-popup.no-account-desc2', ' to register now!')}
                                </p>
                                <div className='line'></div>
                                <p className='msg-tips'>
                                    {t('global:global.login-popup.login-issue-desc1', 'If you encounter any issues while loggin in,')}
                                    <br />
                                    {t('global:global.login-popup.login-issue-desc2', 'please contact our ')}
                                    <span
                                        onClick={() => {
                                            core.openLivechat(this.props.language.countryLanguageKey, this.props.screen.viewType);
                                        }}
                                    >
                                        {t('global:global.login-popup.login-issue-cs', 'Customer Service')}
                                    </span>
                                    {t('global:global.login-popup.login-issue-desc3', ' for further assistance.')}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['register', 'global', 'mobile'])(withRouter(FloatingLogin)));
