import React, { Fragment, Suspense, createPortal } from 'react';
import { Route, Switch, Redirect, withRouter, location } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from 'root/webapp/shared/privateRoute';
import ScrollToTop from 'root/webapp/shared/scrollToTop/scrollToTop';
import FloatingNotification from 'root/webapp/shared/floating-notification/floating-notification';

import Loading from './shared/loading/loading';
import Header from './layout/navbar/component/desktop/navbar';
import Footer from './layout/footer/component/desktop/Footer';
import MHeader from './layout/navbar/component/mobile/Navbar';
import MHeaderMyAccount from './portal/settings/landing/component/navbarMyAccount';
import FloatingRightSideMenu from './layout/sidemenu/component/FloatingRightSideMenu';
import MBottomNavbar from './shared/component/wap/BottomNavbar';
import MFooter from 'root/webapp/layout/footer/component/mobile/Footer';

import Login from './portal/login/mobile/Login';
import Reset from './portal/reset-password/mobile/Reset';

import MDeposit from './portal/settings/deposit/component/mobile/m-deposit';
import Reward from 'root/webapp/shared/reward-popup/component/reward-popup';
import DepositLanding from './portal/settings/deposit/deposit-landing/deposit-landing.selector';
import ForceChangePassword from 'root/components/organisms/force-change-password/force-change-password';

import Utils, { browserHack } from 'root/utils';
import commonUtil from 'root/utils/common-util';
import { routesConfig, Register } from './route-config';

const renderRoutes = (routes, isLogin = false) => {
    return routes.map((route) => {
        const Component = route.component;

        const renderRoute = (props) => {
            return (
                <Suspense fallback={<></>}>
                    <Component {...props} />
                </Suspense>
            );
        };

        return (
            <Route
                exact={route.exact}
                key={route.key}
                path={route.path}
                render={(props) => {
                    if (!isLogin) {
                        return !route.isPrivate ? renderRoute(props) : <Redirect to='/' />;
                    }
                    return renderRoute(props);
                }}
            />
        );
    });
};

class MainRoute extends React.Component {
    viewType = null;

    componentDidUpdate() {
        if (this.viewType === null || this.viewType !== this.props.screen.viewType) {
            this.viewType = this.props.screen.viewType;

            if (this.viewType === 'mobile') {
                Utils.setMetaTag();
                Utils.removeHoverSideEffect();
                browserHack.initEvents();
            }
        }
    }

    render() {
        const { screen, user, language, portal } = this.props;

        let isWeb = screen.viewType === 'web';
        let isLogin = user.isLogin ? user.isLogin : false;
        let isMyAccountModule = window.location.pathname.split('/').includes('myaccount');
        let toastPosition = isWeb ? 'top-center' : 'bottom-center';

        let pathname = window.location.pathname;
        let sliceCount = 2;
        if (pathname.startsWith('/')) {
            pathname = pathname.split('/');
            pathname = pathname.slice(sliceCount)[0];

            switch (pathname) {
                case 'cmd368':
                case 'virtualsports-landing':
                    pathname = 'sports';
                    break;
                default:
                    break;
            }
        }

        let depositPagesSettings = commonUtil.getSettingFromPortalSettings({
            vm: this,
            settingName: 'depositPagesSettings',
            notViewType: true,
        });

        const transitionTime = window.location.search === '?p=login' || window.location.search === '?p=register' ? 0 : 500;

        // Check the settings in FE and BE to enable popup which will force members to reset password
        let showForceChangePasswordPopup = false;
        if (portal?.settings?.promptChangePassword) {
            if (user?.account?.requiredResetPassword) {
                showForceChangePasswordPopup = true;
            }
        }

        return (
            <div className={language.key}>
                <Reward />

                {user?.isLogin && !this.props.portal.isLoading && showForceChangePasswordPopup && <ForceChangePassword />}

                {isWeb && (
                    <Fragment>
                        <ToastContainer
                            position={toastPosition}
                            autoClose={10000}
                            hideProgressBar={true}
                            newestOnTop
                            closeOnClick={true}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover
                        ></ToastContainer>

                        <FloatingNotification />

                        <div className={`header-section`}>
                            <Header />
                        </div>

                        <ScrollToTop>
                            <div className='bg-content-section'>
                                <Switch>
                                    {renderRoutes(routesConfig.web, isLogin)}
                                    {/* default route not defined */}
                                    <Redirect from='*' to={{ pathname: '/home', search: window.location.search }}></Redirect>
                                </Switch>
                            </div>

                            <div className='footer-section'>
                                <Footer />
                            </div>
                        </ScrollToTop>

                        {this.props.portal.isLoading && <Loading className='vw-100 vh-100'></Loading>}
                    </Fragment>
                )}

                {!isWeb && (
                    <div data-logined={isLogin} data-page={isMyAccountModule ? 'account' : pathname}>
                        <div id='fixed-container'>
                            <CSSTransition key='mheader' classNames='fade-next' in={!isMyAccountModule && !this.props.user.isSpecialDialog} timeout={300} unmountOnExit>
                                <MHeader></MHeader>
                            </CSSTransition>

                            <CSSTransition
                                key='mheader-myaccount'
                                classNames='fade-next'
                                in={isMyAccountModule && !this.props.user.isSpecialDialog}
                                timeout={300}
                                unmountOnExit
                            >
                                <MHeaderMyAccount></MHeaderMyAccount>
                            </CSSTransition>

                            <CSSTransition key='login' classNames='slider-y-bottom-in' in={this.props.portal.view.login} timeout={transitionTime} unmountOnExit>
                                <Login data-page={window.location.pathname} />
                            </CSSTransition>

                            <CSSTransition key='register' classNames='slider-y-bottom-in' in={this.props.portal.view.register} timeout={transitionTime} unmountOnExit>
                                <Suspense fallback={<></>}>
                                    <Register />
                                </Suspense>
                            </CSSTransition>

                            <CSSTransition key='resetpassword' classNames='slider-y-bottom-in' in={this.props.portal.view.reset} timeout={500} unmountOnExit>
                                <Reset data-page={window.location.pathname} />
                            </CSSTransition>

                            <CSSTransition key='right-sidemenu' classNames='side-menu' in={this.props.portal.view.sidemenu} timeout={300} unmountOnExit>
                                <FloatingRightSideMenu />
                            </CSSTransition>

                            <CSSTransition key='bottom-navbar' classNames='slider-y-bottom-in' in={isLogin} timeout={500} unmountOnExit>
                                <MBottomNavbar data-page={window.location.pathname} />
                            </CSSTransition>
                        </div>

                        <ToastContainer
                            position={toastPosition}
                            autoClose={10000}
                            hideProgressBar={true}
                            newestOnTop
                            closeOnClick={true}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover
                        ></ToastContainer>

                        <FloatingNotification />

                        <ScrollToTop>
                            <TransitionGroup className='wap-page'>
                                <CSSTransition
                                    key={this.props.location.pathname}
                                    timeout={500}
                                    classNames='slider-fade-next'
                                    onEnter={() => {
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <Fragment>
                                        <div className='bg-content-section'>
                                            <Suspense>
                                                <Switch location={this.props.location}>
                                                    {renderRoutes(
                                                        [
                                                            ...routesConfig.mobile,
                                                            {
                                                                isLogin: true,
                                                                exact: true,
                                                                key: 'deposit',
                                                                path: '/myaccount/deposit',
                                                                component: depositPagesSettings.enableJsonApi ? DepositLanding : MDeposit,
                                                            },
                                                        ],
                                                        isLogin
                                                    )}
                                                    {/* default route not defined */}
                                                    <Redirect from='*' to={{ pathname: '/home', search: window.location.search }}></Redirect>
                                                </Switch>
                                            </Suspense>
                                        </div>

                                        <div className='footer-section'>
                                            <MFooter />
                                        </div>
                                    </Fragment>
                                </CSSTransition>
                            </TransitionGroup>
                        </ScrollToTop>

                        {this.props.portal.isLoading && <Loading className='vw-100 vh-100'></Loading>}
                    </div>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(withRouter(MainRoute));
